import {Component, ElementRef, OnInit} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {PaginationComponent} from "../../../../../core/components/pagination/pagination.component";
import {NewJobFormComponent} from "../new-job-form/new-job-form.component";
import {NgClass, NgIf} from "@angular/common";
import {JobGrid} from "../job-grid/job-grid";
import {ActivatedRoute, NavigationEnd, Router, RouterLink} from "@angular/router";
import {filter} from "rxjs";
import {PlanCardService} from "../../../../services/plan-card.service";

@Component({
    selector: 'app-dashboard',
    standalone: true,
    imports: [
        FormsModule,
        PaginationComponent,
        NewJobFormComponent,
        NgIf,
        JobGrid,
        NgClass,
        RouterLink
    ],
    templateUrl: './dashboard.component.html',
    styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit {
    activeButton = '';

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private planCardService: PlanCardService,
    private elementRef: ElementRef
    ) {}

    ngOnInit() {
        this.setActiveButton();
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.setActiveButton();
        });
    }

    setActiveButton() {
        this.planCardService.initial();
        this.route.queryParams.subscribe(params => {
            this.activeButton = params['status'] || '';
        });
    }

    navigate(status: string) {
        if (status === '') {
            this.router.navigate(['/plan-card-tracker']);

        } else {
            this.router.navigate(['/plan-card-tracker'], { queryParams: { status } });
        }
    }
}