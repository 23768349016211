import {AfterViewInit, Component, effect, ElementRef, inject, OnDestroy, OnInit, signal} from '@angular/core';
import {PaginationComponent} from "../../../../../core/components/pagination/pagination.component";
import {NewJobFormComponent} from "../new-job-form/new-job-form.component";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {PlanCardService} from "../../../../services/plan-card.service";
import {DotLoadingServices, NotificationService} from "../../../../../core";
import {ActivatedRoute} from "@angular/router";
import {combineLatest, map, Observable, take} from "rxjs";
import * as XLSX from 'xlsx';
import {FormsModule} from "@angular/forms";
import {SearchJobCardParams} from "../../../../interface/job-card.entity";
import {JobCardSearchResultDTO} from "../../../../interface/plan-card.entity";
import {EmployeeService} from "../../../../services";
import {Department, EmployeeResultDTO} from "../../../../interface/employee.entity";
import {
    Dropdown,
    DropdownInterface,
    DropdownOptions,
    InstanceOptions,
    Modal,
    Tooltip,
    TooltipInterface,
    TooltipOptions,
} from 'flowbite';
import {endOfDay, endOfMonth, endOfWeek, format, startOfDay, startOfMonth, startOfWeek} from "date-fns";

@Component({
    selector: 'app-job-grid',
    standalone: true,
    imports: [
        NewJobFormComponent,
        PaginationComponent,
        NewJobFormComponent,
        NgIf,
        FormsModule,
        NgClass,
        NgForOf
    ],
    templateUrl: './job-grid.html',
    styleUrl: './job-grid.scss'
})
export class JobGrid implements OnInit, OnDestroy, AfterViewInit {

    employeeService = inject(EmployeeService)
    pageNumber = 1;
    totalItems: number = 0;
    jobCards: JobCardSearchResultDTO[] = [];
    filteredEmployees: any[] = [];
    departmentsDTO: Department[] = []
    filteredDepartments: any[] = [];
    employeeDTO: EmployeeResultDTO[] = []
    showDurationColumn: boolean = true;
    searchTerm = ''
    searchEmp = ''

    isDepartmentListVisible: boolean = false;
    isEmployeeListVisible: boolean = false;

    searchParams: SearchJobCardParams = {
        employee_id: '',
        employee_name: '',
        pp_no: '',
        department: '',
        status: '',
        check_in_date: '',
        check_out_date: '',
        item_per_page: '10',
        page_number: '1',
    }

    constructor(private route: ActivatedRoute,
                private notificationService: NotificationService,
                public dotLoadingServices: DotLoadingServices,
                private planCardService: PlanCardService,
                private elementRef: ElementRef
    ) {
        effect(() => {
            this.mapDepartments();
            const employee = this.employeeService.all()
            this.employeeDTO.push(...employee)
        });
    }

    mapDepartments() {
        const employees = this.employeeService.all();
        if (employees && employees.length > 0) {
            const departments = employees.map(item => item.department);
            const uniqueDepartments = new Set<number>();
            this.departmentsDTO = departments.filter(department => {
                const isDuplicate = uniqueDepartments.has(department.id);
                if (!isDuplicate) {
                    uniqueDepartments.add(department.id);
                    return true;
                }
                return false;
            }).map(department => ({
                id: department.id,
                code: department.code,
                name: department.name,
                departmentStatus: department.departmentStatus
            }));
        }
    }


    searchByParams() {
        this.searchParams = {
            ...this.searchParams,
            pp_no: this.searchParams.pp_no,
        };
        this.fetchJobs(this.searchParams);
    }

    fetchJobs(searchParams: SearchJobCardParams) {
        this.dotLoadingServices.setLoading(true);
        this.planCardService.find(searchParams, true).subscribe({
            next: (response) => {
                if (response.data?.data === null) {
                    this.dotLoadingServices.setLoading(false);
                    this.jobCards = [];
                } else if (response.data?.data) {
                    const {totalItems, page} = response.data;
                    const jobs = response.data.data;
                    this.jobCards = jobs.map(job => ({
                        jobCardId: job.jobCardId,
                        jobStatus: job.jobStatus,
                        unitName: job.unitName,
                        department: job.department,
                        duration: job.duration,
                        quantity: job.quantity,
                        size: job.size,
                        employeeName: job.employeeName,
                        employeeNumber: job.employeeNumber,
                        checkInTime: job.checkInTime,
                        checkOutTime: job.checkOutTime,
                        ppNo: job.ppNo,
                    }));
                    this.totalItems = totalItems;
                    this.pageNumber = page;
                }
                this.dotLoadingServices.setLoading(false);
            },
            error: (error) => {
                this.dotLoadingServices.setLoading(false);
                console.error(error);
            }
        });
    }


    onPageChange(pageNumber: number) {
        this.searchParams = {
            ...this.searchParams,
            page_number: String(pageNumber)
        };
        this.fetchJobs(this.searchParams);
    }


    selectedId: number | null = null;

    toggleSelection(id: number) {
        this.selectedId = this.selectedId === id ? null : id;
        const action = this.selectedId ? this.planCardService.getById(String(id), true) : this.planCardService.initial();

        if (action instanceof Observable) {
            action.subscribe({
                error: (error) => console.error('Error from service:', error)
            });
        }
    }

    handleInteraction(event: Event, id: number) {
        const isRadioClick = (event.target as HTMLElement).closest('input[type="radio"]');
        if (isRadioClick) {
            event.stopPropagation();
        }
        if (isRadioClick || (!this.showDurationColumn && !isRadioClick)) {
            this.toggleSelection(id);

        }
    }

    dropdown: DropdownInterface | null = null;
    selectedTimeRange: string | null = null;


    ngOnInit(): void {
        this.route.queryParams.pipe(
            map(params => params['status'] || '')
        ).subscribe(status => {
            this.searchParams.status = status;
            this.updateDurationColumnVisibility();
            this.fetchJobs(this.searchParams);
        });

        this.planCardService.jobUpdated$.subscribe(() => {
            this.reFetchJobs();
        });

        this.initializeDropdown();
        this.initializeModal();
    }


    ngOnDestroy(): void {
        if (this.dropdown) {
            this.dropdown.destroy();
        }
        document.removeEventListener('click', this.handleDocumentClick.bind(this));
        this.modal = null;
    }

    initializeDropdown(): void {
        const $targetEl = document.getElementById('dropdown');
        const $triggerEl = document.getElementById('dropdownButton');

        if ($targetEl && $triggerEl) {
            const options: DropdownOptions = {
                placement: 'bottom',
                triggerType: 'click',
                offsetSkidding: 0,
                offsetDistance: 10,
                delay: 300,
            };

            const instanceOptions: InstanceOptions = {
                id: 'dropdown',
                override: true
            };

            this.dropdown = new Dropdown($targetEl, $triggerEl, options, instanceOptions);
            document.addEventListener('click', this.handleDocumentClick.bind(this));
        }
    }

    private modal: Modal | null = null;

    private initializeModal(): void {
        const modalElement = document.getElementById('crud-modal');
        if (modalElement) {
            this.modal = new Modal(modalElement, {
                placement: 'center',
                backdrop: 'static',
                closable: true,
            });
        }
    }

    toggleModal(show: boolean): void {
        if (show) {
            this.modal?.show();
        } else {
            this.clearSearch();
            this.modal?.hide();
            this.filteredDepartments = [...this.filteredDepartments];
            this.isDepartmentListVisible = false;
            this.isEmployeeListVisible = false;
        }
    }

    handleDocumentClick(event: MouseEvent): void {
        const $targetEl = document.getElementById('dropdown');
        const $triggerEl = document.getElementById('dropdownButton');

        if ($targetEl && $triggerEl && this.dropdown) {
            if (!$targetEl.contains(event.target as Node) && !$triggerEl.contains(event.target as Node)) {
                this.dropdown.hide();
            }
        }
    }

    getDurationDisplay(status: string, duration: string | null | undefined): string {
        if (status.toLowerCase() === 'started') {
            return '-';
        }
        if (this.searchParams.status === '') {
            if (status.toLowerCase() === 'completed') {
                return duration ?? '-';
            }
            return '-';
        }
        return duration ?? '-';
    }

    updateDurationColumnVisibility() {
        this.showDurationColumn = this.searchParams.status !== 'started';
    }

    exportToCSV() {
        if (!this.searchParams.check_in_date || !this.searchParams.check_out_date) {
            this.notificationService.showNotification({
                type: 'alert', message: `Please select a date range to download the report.`,
            });
            return;
        }
        this.searchParams = {
            ...this.searchParams,
            item_per_page: '',
            employee_id: this.selectedEmployeeId,
        };
        combineLatest([
            this.route.queryParams.pipe(take(1), map(params => params['status'] || '')),
            this.planCardService.find(this.searchParams, true).pipe(take(1), map(response => response.data?.data ?? [])),
        ]).pipe(
            take(1),
            map(([status, data]) => {
                const isStarted = status.toLowerCase() === 'started';
                const formattedData = data.filter((job: any) =>
                    !status || job.jobStatus.toLowerCase() === status.toLowerCase()
                ).map((job: any) => {
                    const rowData = [
                        job.employeeNumber,
                        job.employeeName,
                        job.department.replace(/[^\w\s-]/g, '-'),
                        job.ppNo,
                        job.jobStatus,
                        job.checkInTime,
                    ];
                    if (!isStarted) {
                        rowData.push(job.quantity, job.duration, job.unitName, job.checkOutTime,job.size.toUpperCase());
                    }
                    return rowData;
                });
                return [status, formattedData, isStarted];
            }),
        ).subscribe({
            next: ([status, formattedData, isStarted]) => {
                if (formattedData.length) {
                    this.searchParams = {
                        ...this.searchParams,
                        department: this.searchParams.department,
                        check_in_date: this.searchParams.check_in_date,
                        check_out_date: this.searchParams.check_out_date,
                        status: status,
                    };

                    const header = [
                        'Employee No',
                        'Employee Name',
                        'Department',
                        'PP No',
                        'Status',
                        'Check-In Time',
                    ];
                    if (!isStarted) {
                        header.push('Quantity', 'Duration', 'Unit', 'Check-Out Time','Size');
                    }
                    this.csv([header, ...formattedData]);
                    this.toggleModal(false);
                } else {
                    this.notificationService.showNotification({
                        type: 'error', message: `No data available to export.`,
                    });
                }
            },
            error: (error) => {
                this.notificationService.showNotification({
                    type: 'error', message: `Error generating Excel report.`,
                });
                console.error(error);
            }
        });
    }

    csv(data: any[]): void {
        try {
            const today = new Date().toISOString().split('T')[0];
            const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
            const csvData: string = XLSX.utils.sheet_to_csv(worksheet);
            const blob: Blob = new Blob([csvData], {type: 'text/csv;charset=utf-8;'});
            const link: HTMLAnchorElement = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${today}.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            this.notificationService.showNotification({
                type: 'error', message: `Error generating CSV file:.`,
            });
            console.error(error);
        }
    }

    updateTimeRange(range: string): void {
        this.selectedTimeRange = range === 'All Time' ? null : range;
        const today = new Date();

        const dateRanges: { [key: string]: () => [Date, Date] } = {
            'Today': () => [startOfDay(today), endOfDay(today)],
            'This Week': () => [startOfWeek(today), endOfWeek(today)],
            'This Month': () => [startOfMonth(today), endOfMonth(today)],
            'All Time': () => [new Date(0), endOfDay(today)]
        };

        const [startDate, endDate] = dateRanges[range] ? dateRanges[range]() : [startOfDay(today), endOfDay(today)];

        this.searchParams = {
            ...this.searchParams,
            check_in_date: format(startDate, 'yyyy-MM-dd'),
            check_out_date: format(endDate, 'yyyy-MM-dd')
        };

        this.fetchJobs(this.searchParams);

        if (this.dropdown) {
            this.dropdown.hide();
        }
    }

    clearSearch() {
        this.searchParams = {
            employee_id: '',
            employee_name: '',
            pp_no: '',
            department: '',
            status: this.searchParams.status,
            check_in_date: '',
            check_out_date: '',
            page_number: '1',
            item_per_page: '10'
        };
        this.searchTerm = '';
        this.pageNumber = 1;
        this.searchEmp = '';
        this.updateDurationColumnVisibility();
        this.fetchJobs(this.searchParams);
        this.selectedTimeRange = null;
    }

    reFetchJobs() {
        this.fetchJobs(this.searchParams);
    }

    filterDepartments() {
        const searchTerm = this.searchTerm.toLowerCase();
        this.filteredDepartments = this.departmentsDTO.filter(employee =>
            employee.name.toLowerCase().includes(searchTerm)
        );
        this.isDepartmentListVisible = this.filteredDepartments.length > 0;
    }

    selectAll(event: MouseEvent): void {
        (event.target as HTMLInputElement).select();
    }

    selectDepartment(department: any) {
        this.searchParams.department = department.name;
        this.searchTerm = department.name;
        this.isDepartmentListVisible = false;
        this.isEmployeeListVisible = false;

        this.searchEmp = '';
        this.selectedEmployeeId = '';
    }

    getReportTitle(): string {
        return this.searchParams.status
            ? `${this.searchParams.status.charAt(0).toUpperCase() + this.searchParams.status.slice(1)} Jobs Report`
            : 'All Jobs Report';
    }


    minEndDate: string = '';

    updateMinEndDate() {
        const {check_in_date, check_out_date} = this.searchParams;

        if (check_in_date) {
            const startDate = new Date(check_in_date);
            startDate.setDate(startDate.getDate());
            this.minEndDate = startDate.toISOString().split('T')[0];

            if (check_out_date && new Date(check_out_date) < startDate) {
                this.searchParams.check_out_date = this.minEndDate;
            }
        }
    }

    filterEmployees() {
        const searchTerm = this.searchEmp.toLowerCase();
        this.filteredEmployees = this.employeeDTO.filter(employee =>
            employee.code.toLowerCase().includes(searchTerm) ||
            employee.displayName.toLowerCase().includes(searchTerm)
        );
        this.isEmployeeListVisible = this.filteredEmployees.length > 0;

        if (this.searchEmp !== this.selectedEmployeeId) {
            this.searchParams.department = '';
            this.searchTerm = '';
        }
    }

    selectedEmployeeId = '';

    selectedEmployee(employee: any) {
        this.isEmployeeListVisible = false;
        this.searchEmp = employee.displayName;
        this.selectedEmployeeId = employee.code;

        const employeeDepartment = this.employeeDTO.find(emp => emp.code === employee.code)?.department;

        if (employeeDepartment) {
            this.searchParams.department = employeeDepartment.name;
            this.searchTerm = employeeDepartment.name;
            this.isDepartmentListVisible = false;
        }
    }

    ngAfterViewInit() {
        this.initializeTooltip();
    }

    private tooltip!: TooltipInterface;

    private initializeTooltip() {
        const $targetEl: HTMLElement = this.elementRef.nativeElement.querySelector('#tooltip');
        const $triggerEl: HTMLElement = this.elementRef.nativeElement.querySelector('#tooltip-trigger');

        const options: TooltipOptions = {
            placement: 'bottom',
            triggerType: 'hover',
        };

        this.tooltip = new Tooltip($targetEl, $triggerEl, options);
    }


}

