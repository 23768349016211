import {Component, effect, inject, signal} from '@angular/core';
import {EmployeeService} from "../../../../services";
import {EmployeeResultDTO} from "../../../../interface/employee.entity";
import {FormsModule} from "@angular/forms";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {UnitDTO} from "../../../../interface/unit.entity";
import {UnitService} from "../../../../services/unit.service";
import {DotLoadingServices, NotificationService} from "../../../../../core";
import {PlanCardService} from "../../../../services/plan-card.service";


@Component({
    selector: 'app-new-job-form',
    standalone: true,
    imports: [
        FormsModule,
        NgForOf,
        NgIf,
        NgClass
    ],
    templateUrl: './new-job-form.component.html',
    styleUrl: './new-job-form.component.scss'
})
export class NewJobFormComponent {

    employeeService = inject(EmployeeService)
    unitService = inject(UnitService)
    employeeDTO: EmployeeResultDTO[] = []
    planCardService = inject(PlanCardService);

    filteredEmployees: any[] = [];
    unitDTO: UnitDTO[] = []
    jobId = signal<number>(0);
    availableJobs = signal<number>(0);
    isPPValid = true;
    isListVisible: boolean = false
    searchTerm: string = '';
    createPlanCardDTO = {
        ppNo: '',
        department: '',
        empNo: '',
        empName: '',
        checkInTime: '',
        checkOutTime: '',
        quantity: '',
        duration: '',
        unitId: -1,
        departmentId: -1,
        size: '',
    }

    constructor(private dotLoadingServices: DotLoadingServices,
                private notificationService: NotificationService) {
        effect(() => {
            const employee = this.employeeService.all()
            this.employeeDTO.push(...employee)
            const unit = this.unitService.all()
            this.unitDTO.push(...unit)
        });
        effect(() => {
            const activeJob = this.planCardService.active()
            if (activeJob) {
                this.jobId.set(<number>activeJob?.jobCardID)
                this.searchTerm = activeJob?.employeeNumber
                this.createPlanCardDTO.empName = activeJob?.employeeName
                this.createPlanCardDTO.department = activeJob?.deparment
                this.createPlanCardDTO.empNo = activeJob?.employeeNumber
                this.createPlanCardDTO.checkInTime = activeJob?.checkInTime
                this.createPlanCardDTO.checkOutTime = <string>activeJob?.checkOutTime
                this.createPlanCardDTO.duration = <string>activeJob?.duration
                this.createPlanCardDTO.ppNo = <string>activeJob?.ppNumber
                this.createPlanCardDTO.quantity = <string>activeJob?.quantity
                this.validateForm()
            } else {
                this.resetForm()
            }
        }, {allowSignalWrites: true});
        effect(() => {
            this.ppFocus()
        });
    }

    create() {
        const updatePlanCardDTO={
            quantity:this.createPlanCardDTO.quantity,
            size:this.createPlanCardDTO.size,
            unitId:this.createPlanCardDTO.unitId,
        }
        const operation = this.jobId() > 0

            ? this.planCardService.update(this.jobId(), updatePlanCardDTO)
            : this.planCardService.create(this.createPlanCardDTO);
        operation.subscribe({
            next: (result) => {
                this.dotLoadingServices.setLoading(false);
                this.planCardService.initial();
                this.notificationService.showNotification({
                    type: 'success',
                    message: `Job '${this.createPlanCardDTO.ppNo}' ${this.jobId() > 0 ? ' completed' : 'started'} successfully`,
                });
                this.resetForm();
                this.ppFocus();
            },
            error: (error) => {
                console.error('Error creating/updating job:', error);
                this.notificationService.showNotification({
                    type: 'error',
                    message: `Failed to ${this.jobId() > 0 ? ' complete' : 'start'} the job.`,
                });
            }
        });
    }

    selectAll(event: MouseEvent): void {
        (event.target as HTMLInputElement).select();
    }

    filterByEmployeeName() {
        const employeeNameOrCode = String(this.createPlanCardDTO.empName);
        let selectedEmployee;
        selectedEmployee = this.employeeDTO.find(employee => employee.displayName.toLowerCase().includes(employeeNameOrCode.toLowerCase()));
        this.createPlanCardDTO.empName = selectedEmployee?.displayName || '';
        this.createPlanCardDTO.empNo = selectedEmployee?.code || '';
        this.createPlanCardDTO.department = selectedEmployee?.department.name || '';
        this.validateForm();
    }


    validatePP() {
        const validPP = /^PP\d{6}\/\d{6}$/;
        this.isPPValid = validPP.test(this.createPlanCardDTO.ppNo);
        this.validateForm();
    }

    isFormValid: boolean = false;

    validateForm() {
        const {ppNo, empNo, empName, department, unitId, quantity} = this.createPlanCardDTO;

        const baseValidation =
            this.isPPValid &&
            [ppNo, empNo, empName, department].every(Boolean);

        if (this.jobId() > 0) {
            const isQuantityValid = quantity !== null && quantity !== undefined && quantity !== '';
            this.isFormValid = baseValidation && Boolean(unitId) && isQuantityValid;
        } else {
            this.isFormValid = baseValidation;
        }

    }

    numberOnly(event: KeyboardEvent): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode === 46) {
            const inputValue: string = (event.target as HTMLInputElement).value;
            if (inputValue.indexOf('.') > -1) {
                return false;
            }
        }
        return (charCode === 8 || charCode === 9 || charCode === 13 || charCode === 46 ||
            (charCode >= 48 && charCode <= 57));
    }


    ppFocus() {
        setTimeout(() => {
            const ppInput = document.getElementById('ppNumberInput') as HTMLInputElement;
            if (ppInput) {
                ppInput.focus();
            }
        }, 0);
    }

    resetForm() {
        this.createPlanCardDTO = {
            ppNo: '',
            department: '',
            empNo: '',
            empName: '',
            checkInTime: '',
            checkOutTime: '',
            quantity: '',
            duration: '',
            unitId: 0,
            departmentId: 0,
            size: '',
        };
        this.searchTerm = '';
        this.isListVisible = false;
        this.jobId.set(0);
        this.validateForm();
    }


    filterEmployees() {
        const searchTerm = this.searchTerm.toLowerCase();
        this.filteredEmployees = this.employeeDTO.filter(employee =>
            employee.code.toLowerCase().includes(searchTerm) ||
            employee.displayName.toLowerCase().includes(searchTerm)
        );

        this.isListVisible = this.filteredEmployees.length > 0;
    }

    selectEmployee(employee: any) {
        this.createPlanCardDTO.empNo = employee.code;
        this.createPlanCardDTO.empName = employee.displayName;
        this.searchTerm = employee.code;
        this.createPlanCardDTO.department = employee.department.name;
        this.createPlanCardDTO.departmentId = employee.department.id;
        this.isListVisible = false;
        this.searchTerm = employee.code;
        this.planCardService.check(employee.displayName, true).subscribe({
            next: (result) => {
                const checkJobs = result.data
                this.availableJobs.set(checkJobs.value)
            }
        })
        this.validateForm();
    }

}
