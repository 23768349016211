

export enum APIRequestResources {
  ApplicationService = 'application',
  AuthService = 'auth',
  EmployeeService ='employee',
  PlanCardService ='job-card',
  UnitService ='unit',

}
