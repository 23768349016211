<div class="col-span-1 rounded-lg p-4  h-fit bg-white">
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-4">

        <div class="text-xl font-semibold md:col-span-2 lg:col-span-1">
            {{ jobId() > 0 ? 'Complete Job' : 'New Job' }}
        </div>
        <div>
            <div class="relative">
                <input type="text"
                       id="ppNumberInput"
                       aria-describedby="pp_number_error_help"
                       class="floating-btn peer"
                       [ngClass]="{'border-red-600 focus:border-red-600': !isPPValid, 'border-gray-300 focus:border-blue-600': isPPValid}"
                       [(ngModel)]="createPlanCardDTO.ppNo"
                       (input)="validatePP()"
                       [disabled]="jobId() > 0"
                       placeholder=" "  />
                <label for="ppNumberInput"
                       class=" floating-lbl"
                       [ngClass]="{'text-red-600': !isPPValid, 'text-gray-500': isPPValid}">
                    PP Number <span class="text-red-700">*</span>
                </label>
            </div>
            @if (!isPPValid) {
                <p
                   id="pp_number_error_help"
                   class="mt-2 text-xs text-red-600">
                    <span class="font-medium">Invalid PP Number format.</span> It should be in the format PP000000/000000
                </p>
            }
        </div>

        <div>
            <div class="searchable-dropdown">
                <input (click)="selectAll($event)"
                       type="text"
                       [disabled]="jobId() > 0"
                       id="employee.number"
                       class="floating-btn peer"
                       (focus)="isListVisible = true"
                       (input)="filterEmployees()"
                       [(ngModel)]="searchTerm"
                       placeholder=" "
                />
                @if (isListVisible) {
                    <ul class="list">
                        @for (option of filteredEmployees.slice(0, 5); track option.id) {
                            <li  class="item" (click)="selectEmployee(option)">
                                {{ option.code}} - {{ option.displayName}}
                            </li>
                        }
                    </ul>
                }
                <label for="employee.number" class="floating-lbl">Employee Number <span class="text-red-700">*</span></label>
            </div>
        </div>

        <div>
            <div class="relative">
                <input type="text" id="employee.name"  disabled class="floating-btn peer cursor-not-allowed" (ngModelChange)="filterByEmployeeName()"
                       [(ngModel)]="createPlanCardDTO.empName" placeholder=" "/>
                <label for="employee.name" class="floating-lbl">Employee Name<span class="text-red-700">*</span></label>
            </div>
        </div>
        <div>
            <div class="relative">
                <input type="text" id="employee.department" class="floating-btn peer cursor-not-allowed" disabled
                       [(ngModel)]="createPlanCardDTO.department" placeholder=" "/>
                <label for="employee.department" class="floating-lbl">Department<span class="text-red-700">*</span></label>
            </div>
        </div>
        @if (jobId()) {
            <div>
                <div class="relative">
                    <select id="reqBy" [(ngModel)]="createPlanCardDTO.unitId"
                            (ngModelChange)="validateForm()"
                            class="floating-btn peer"
                            required>
                        <option value="" selected disabled>Select a unit</option>
                        @for (unit of unitDTO; track unit) {
                            <option [value]="unit.id">{{ unit.unitName }}</option>
                        }
                    </select>
                    <label for="reqBy" class="floating-lbl">Unit <span class="text-red-700">*</span></label>
                </div>
            </div>
            <div>
                <div class="relative">
                    <input type="text"
                    id="sizeInput"
                    aria-describedby="pp_number_error_help"
                    class="floating-btn peer"
                    [(ngModel)]="createPlanCardDTO.size"
                    placeholder=" "  />
                    <label for="sizeInput" class="floating-lbl">Size </label>
                </div>
            </div>
            <div>
                <div class="relative">
                    <input type="number"
                           id="count"
                           class="floating-btn peer"
                           [min]="0"
                           (keypress)="numberOnly($event)"
                           [(ngModel)]="createPlanCardDTO.quantity"
                           (ngModelChange)="validateForm()"
                           placeholder=" "/>
                    <label for="count" class="floating-lbl">Quantity <span class="text-red-700">*</span></label>
                </div>
            </div>
        }

        <div class="md:col-span-2 lg:col-span-1 align">
            <button id="Start"
                    [ngClass]="{'start-button': isFormValid, 'disabled-button': !isFormValid}"
                    type="button"
                    [disabled]="!isFormValid"
                    (click)="create()">
                {{ jobId() > 0 ? 'Complete Job' : 'Start Job' }}
            </button>
        </div>
    </div>
</div>
