<nav class="bg-white shadow-md">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-center items-center h-16">
            <div class="flex items-center space-x-4">
                <button class="nav-button" [ngClass]="{'bg-gray-100': activeButton === 'started'}" (click)="navigate('started')">Started</button>
                <button class="nav-button" [ngClass]="{'bg-gray-100': activeButton === 'completed'}" (click)="navigate('completed')">Completed</button>
                <button class="nav-button" [ngClass]="{'bg-gray-100': activeButton === ''}" (click)="navigate('')">All</button>
            </div>
        </div>
    </div>
</nav>

<div class="grid grid-cols-1 px-9 p-4 gap-4">
    <app-job-grid></app-job-grid>
</div>