<div class="grid grid-cols-1 lg:grid-cols-4 gap-6">
    <div class="col-span-1 lg:col-span-3 bg-white rounded-lg p-4 sm:p-6">
        <div class="flex flex-col md:flex-row md:items-center md:justify-between space-y-4 md:space-y-0">
            <!-- Left-aligned group: Search field and This Month dropdown -->
            <div class="flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-2">
                <!-- Search field and button -->
                <div class="w-full md:w-auto">
                    <div class="flex">
                        <div class="relative flex-grow">
                            <input type="text" id="voice-search" (keyup.enter)="searchByParams()"
                                   [(ngModel)]="searchParams.pp_no"
                                   class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                                   placeholder="Search PP Number" required/>
                        </div>
                        <button (click)="searchByParams()" type="submit"
                                class="inline-flex justify-center items-center py-2.5 px-3 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
                            <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2"
                                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                            </svg>
                            <span class="sr-only">Search</span>
                        </button>
                    </div>
                </div>

                <button id="dropdownButton"
                        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
                        type="button">
                    {{ selectedTimeRange || 'All Time' }}
                    <svg class="ml-2 w-4 h-4" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                </button>

                <div id="dropdown" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
                    <ul class="py-2 text-sm text-gray-700" aria-labelledby="dropdownButton">
                        <li>
                            <div class="block px-4 py-2 hover:bg-gray-100" (click)="updateTimeRange('All Time')">All
                                Time
                            </div>
                        </li>
                        <li>
                            <div class="block px-4 py-2 hover:bg-gray-100" (click)="updateTimeRange('Today')">Today
                            </div>
                        </li>
                        <li>
                            <div class="block px-4 py-2 hover:bg-gray-100" (click)="updateTimeRange('This Week')">This
                                Week
                            </div>
                        </li>
                        <li>
                            <div class="block px-4 py-2 hover:bg-gray-100" (click)="updateTimeRange('This Month')">This
                                Month
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- Right-aligned: Download Report button -->
            <div class="w-full md:w-auto mt-2 md:mt-0">
                <button (click)="toggleModal(true)"
                        class="w-full md:w-auto whitespace-nowrap inline-flex justify-center items-center py-2 px-3 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
                    Download Report
                </button>
            </div>
        </div>


        <div class="relative overflow-x-auto mt-4">
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">
                    <thead class="text-xs text-gray-700 bg-gray-50">
                    <tr>
                        @if (!showDurationColumn) {
                            <th scope="col" class="p-4">
                                Select
                            </th>
                        }
                        <th scope="col" class="px-6 py-3">
                            PP No
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Employee Name
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Department
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Started Time
                        </th>
                        @if (showDurationColumn) {
                            <th scope="col" class="px-6 whitespace-nowrap py-3">Duration</th>
                            <th scope="col" class="px-6 whitespace-nowrap py-3">Size</th>
                        }
                        <th scope="col" class="px-6 py-3">
                            Status
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        @if (jobCards.length > 0 || null) {
                            @for (item of jobCards; track item.jobCardId) {
                                <tr class="bg-white border-b hover:bg-gray-50 " (click)="handleInteraction($event, item.jobCardId)">
                                    @if (!showDurationColumn) {
                                        <td class="w-4 p-4">
                                            <div class="flex items-center">
                                                <input aria-describedby="helper-radio-text"
                                                       type="radio"
                                                       name="jobSelection"
                                                       [value]="item.jobCardId"
                                                       [checked]="selectedId === item.jobCardId"
                                                       class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300">
                                            </div>
                                        </td>
                                    }
                                    <th scope="row"
                                        class="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">{{ item.ppNo }}
                                    </th>
                                    <td class="px-6 py-3">{{ item.employeeName }}</td>
                                    <td class="px-6 py-3">{{ item.department }}</td>
                                    <td class="px-6 py-3">{{ item.checkInTime }}</td>
                                    @if (showDurationColumn) {
                                        <td class="px-6 py-3">
                                            <div class="flex items-center justify-start">
                                                <span class="inline-block w-8 text-right">{{ getDurationDisplay(item.jobStatus, item.duration) }}</span>
                                                @if (item.jobStatus.toLowerCase() !== 'started') {
                                                    <span class="ml-1">min</span>
                                                }
                                            </div>
                                        </td>
                                        <td class="px-6 py-3">{{ item.size.toUpperCase() }}</td>
                                    }
                                    <td class="px-6 py-3"><span class="status-badge"
                                                                [ngClass]="item.jobStatus.toLowerCase()">{{ item.jobStatus.toUpperCase() }}</span>
                                    </td>
                                </tr>
                            }

                        } @else {
                            <tr>
                                <td colspan="8" class="px-6 py-10">
                                    <div class="flex flex-col items-center justify-center text-center">
                                        <p class="text-xl font-semibold text-gray-700 mb-2">No results found</p>
                                        <p class="text-gray-500">We couldn't find any items matching your search
                                            criteria.</p>
                                        <button (click)="clearSearch()"
                                                class="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300 ease-in-out">
                                            Clear Search
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                <app-pagination [totalItems]="totalItems" [itemsPerPage]="10"
                                (pageChanged)="onPageChange($event)"></app-pagination>
            </div>
        </div>
    </div>
    <app-new-job-form></app-new-job-form>
</div>

<!-- Report modal -->
<div id="crud-modal" tabindex="-1" aria-hidden="true"
     class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative p-4 w-full max-w-md max-h-full">
        <div class="relative bg-white rounded-lg shadow ">
            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                <h3 class="text-lg font-semibold text-gray-900 ">
                    {{ getReportTitle() }}
                </h3>
                <svg data-tooltip-target="tooltip"  id="tooltip-trigger"  data-tooltip-placement="bottom" class="ml-4 w-6 h-6 text-blue-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 28 28">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.529 9.988a2.502 2.502 0 1 1 5 .191A2.441 2.441 0 0 1 12 12.582V14m-.01 3.008H12M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                </svg>

                <div id="tooltip" role="tooltip"   class="absolute z-30 invisible inline-block px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 tooltip">
                    For specific employee data, select an employee. To view department-wide information, leave the employee field empty. Always specify a date range.
                    <div class="tooltip-arrow" data-popper-arrow></div>
                </div>


                <button type="button" (click)="toggleModal(false)"
                        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center">
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                         viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button>
            </div>
            <!-- Modal body -->
            <div class="p-4 md:p-5">
                <div class="grid gap-4 mb-4 grid-cols-2">
                    <div class="col-span-2 searchable-dropdown">
                        <input (click)="selectAll($event)"
                               [ngModelOptions]="{standalone: true}"
                               type="text"
                               id="department"
                               class="floating-btn peer"
                               (focus)="isDepartmentListVisible = true"
                               (input)="filterDepartments()"
                               [(ngModel)]="searchTerm"
                               placeholder=" "
                        />
                        @if (isDepartmentListVisible) {
                            <ul class="list">
                                @for (option of filteredDepartments.slice(0, 2); track option.id) {
                                    <li class="item" (click)="selectDepartment(option)">
                                        {{ option.name }}
                                    </li>
                                }
                            </ul>
                        }
                        <label for="department" class="floating-lbl">Select Department</label>
                    </div>
                    <div class="col-span-2 searchable-dropdown">
                        <input (click)="selectAll($event)"
                               [ngModelOptions]="{standalone: true}"
                               type="text"
                               id="employee"
                               class="floating-btn peer"
                               (focus)="isEmployeeListVisible = true"
                               (input)="filterEmployees()"
                               [(ngModel)]="searchEmp"
                               placeholder=" "
                        />
                        @if (isEmployeeListVisible) {
                            <ul class="list">
                                @for (option of filteredEmployees.slice(0, 2); track option.id) {
                                    <li  class="itemEmp" (click)="selectedEmployee(option)">
                                        {{ option.displayName}}
                                    </li>
                                }
                            </ul>
                        }
                        <label for="employee" class="floating-lbl">Employee Name</label>
                    </div>

                    <div class="col-span-2 sm:col-span-1">
                        <label for="Stdate" class="block mb-2 text-sm font-medium text-gray-900">Start Date</label>
                        <input type="date" name="startDate" [(ngModel)]="searchParams.check_in_date" id="Stdate"
                               (change)="updateMinEndDate()"
                               class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                               placeholder="Type product name" required>
                    </div>
                    <div class="col-span-2 sm:col-span-1">
                        <label for="endDate" class="block mb-2 text-sm font-medium text-gray-900">End Date</label>
                        <input type="date" name="endDate" [(ngModel)]="searchParams.check_out_date" id="endDate"
                               [min]="minEndDate"
                               class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                               placeholder="Type product name" required>
                    </div>
                </div>

                <button type="submit" (click)="exportToCSV()"
                        class="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                    Download File
                </button>
            </div>
        </div>
    </div>
</div>
